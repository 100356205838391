import { useState } from "react";
import "./Testimonial.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { Autoplay } from "swiper/modules";
import { Link } from "react-router-dom";
import { FaArrowAltCircleRight } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { MdCancel } from "react-icons/md";

export const OurPortfolio = () => {
  return (
    <>
      <section
        className="teals-testimonials teals-testimonials-new-sec"
        id="price-plans-section"
      >
        <div className="container shadow">
          <div className="row align-items-center">
            <div className="col-lg-4">
              <div className="testimonial-title">
                <h3>Best of Your work</h3>
                <h2>Latest Project</h2>
              </div>
            </div>
            <div className="col-lg-8">
              <ul className="nav nav-tabs d-lg-flex" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="home-tab09"
                    data-bs-toggle="tab"
                    data-bs-target="#home-tab-pane09"
                    type="button"
                    role="tab"
                    aria-controls="home-tab-pane09"
                    aria-selected="true"
                  >
                    Logo Designs
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="profile-tab08"
                    data-bs-toggle="tab"
                    data-bs-target="#profile-tab-pane08"
                    type="button"
                    role="tab"
                    aria-controls="profile-tab-pane08"
                    aria-selected="false"
                  >
                    Websites
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="profile-tab07"
                    data-bs-toggle="tab"
                    data-bs-target="#profile-tab-pane07"
                    type="button"
                    role="tab"
                    aria-controls="profile-tab-pane07"
                    aria-selected="false"
                  >
                    Stationary
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="profile-tab06"
                    data-bs-toggle="tab"
                    data-bs-target="#profile-tab-pane06"
                    type="button"
                    role="tab"
                    aria-controls="#profile-tab-pane06"
                    aria-selected="false"
                  >
                    Branding
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="profile-tab05"
                    data-bs-toggle="tab"
                    data-bs-target="#profile-tab-pane05"
                    type="button"
                    role="tab"
                    aria-controls="#profile-tab-pane05"
                    aria-selected="false"
                  >
                    Digital Marketing
                  </button>
                </li>
              </ul>
            </div>
            <div className="col-lg-12">
              {/* WEBSITE PRICE PLAN SECTION */}
              <div className="tab-content accordion ghm" id="myTabContent">
                <div
                  className="tab-pane fade show active accordion-item"
                  id="home-tab-pane09"
                  role="tabpanel"
                  aria-labelledby="home-tab09"
                  tabindex="0"
                >
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show  d-lg-block"
                    aria-labelledby="headingOne"
                    data-bs-parent="#myTabContent"
                  >
                    <div className="container">
                      <div className="row gallery align-items-center">
                        <div className="col-sm-12 col-lg-6 mt-5">
                          <img src={require("../assets/logo1.png")} />
                        </div>

                        <div className="col-sm-12 col-lg-6 mt-5">
                          <div className="row" style={{ gap: "20px 0px" }}>
                            <div className="col-lg-6">
                              <img
                                src={require("../assets/logo2.png")}
                              />
                            </div>
                            <div className="col-lg-6">
                              <img
                                src={require("../assets/logo3.png")}
                              />
                            </div>
                            <div className="col-lg-6">
                              <img
                                src={require("../assets/logo4.png")}
                              />
                            </div>
                            <div className="col-lg-6">
                              <img
                                src={require("../assets/logo5.png")}
                              />
                            </div>
                          </div>
                        </div>

                        <div
                          className="custom-price-button"
                          style={{ paddingTop: "70px" }}
                        >
                          <button>Get Started</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* WEBSITE PRICE SECTION END */}

              {/* LOGOS PRICE PLAN Start */}
              <div
                className="tab-pane fade accordion-item prr"
                id="profile-tab-pane08"
                role="tabpanel"
                aria-labelledby="profile-tab08"
                tabindex="0"
              >
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse d-lg-block"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#myTabContent"
                >
                    <div className="container">
                      <div className="row gallery align-items-center">
                        <div className="col-sm-12 col-lg-6 mt-5">
                          <img src={require("../assets/websitePort.png")} />
                        </div>

                        <div className="col-sm-12 col-lg-6 mt-5">
                          <div className="row" style={{ gap: "20px 0px" }}>
                            <div className="col-lg-6">
                              <img
                                src={require("../assets/WebLandingPage.png")}
                              />
                            </div>
                            <div className="col-lg-6">
                              <img
                                src={require("../assets/TBMovingandStorageLogoConcept.png")}
                              />
                            </div>
                            <div className="col-lg-6">
                              <img
                                src={require("../assets/TechLandingPageTemplateforFigma.png")}
                              />
                            </div>
                            <div className="col-lg-6">
                              <img
                                src={require("../assets/LandingPageJoelJota.png")}
                              />
                            </div>
                          </div>
                        </div>

                        <div
                          className="custom-price-button"
                          style={{ paddingTop: "70px" }}
                        >
                          <button>Get Started</button>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
              {/* LOGOS PRICE PLAN END */}

              {/* BRANDING PRICE PLAN Start */}
              <div
                className="tab-pane fade accordion-item prr"
                id="profile-tab-pane07"
                role="tabpanel"
                aria-labelledby="profile-tab07"
                tabindex="0"
              >
                  <div
                  id="collapseTwo"
                  className="accordion-collapse collapse d-lg-block"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#myTabContent"
                >
                  <div className="container">
                      <div className="row gallery align-items-center">
                        <div className="col-sm-12 col-lg-6 mt-5">
                          <img src={require("../assets/stationary1.png")} />
                        </div>

                        <div className="col-sm-12 col-lg-6 mt-5">
                          <div className="row" style={{ gap: "20px 0px" }}>
                            <div className="col-lg-6">
                              <img
                                src={require("../assets/stationary2.png")}
                              />
                            </div>
                            <div className="col-lg-6">
                              <img
                                src={require("../assets/stationary3.png")}
                              />
                            </div>
                            <div className="col-lg-6">
                              <img
                                src={require("../assets/stationary4.png")}
                              />
                            </div>
                            <div className="col-lg-6">
                              <img
                                src={require("../assets/stationary5.png")}
                              />
                            </div>
                          </div>
                        </div>

                        <div
                          className="custom-price-button"
                          style={{ paddingTop: "70px" }}
                        >
                          <button>Get Started</button>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
              {/* BRANDING PRICE PLAN END  */}

              {/* Social Media Marketing PRICE PLAN Start */}
              <div
                className="tab-pane fade accordion-item prr"
                id="profile-tab-pane06"
                role="tabpanel"
                aria-labelledby="profile-tab06"
                tabindex="0"
              >
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse d-lg-block"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#myTabContent"
                >
                  <div className="container">
                      <div className="row gallery align-items-center">
                        <div className="col-sm-12 col-lg-6 mt-5">
                          <img src={require("../assets/branding1.png")} />
                        </div>

                        <div className="col-sm-12 col-lg-6 mt-5">
                          <div className="row" style={{ gap: "20px 0px" }}>
                            <div className="col-lg-6">
                              <img
                                src={require("../assets/branding2.png")}
                              />
                            </div>
                            <div className="col-lg-6">
                              <img
                                src={require("../assets/branding3.png")}
                              />
                            </div>
                            <div className="col-lg-6">
                              <img
                                src={require("../assets/branding4.png")}
                              />
                            </div>
                            <div className="col-lg-6">
                              <img
                                src={require("../assets/branding5.png")}
                              />
                            </div>
                          </div>
                        </div>

                        <div
                          className="custom-price-button"
                          style={{ paddingTop: "70px" }}
                        >
                          <button>Get Started</button>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
              <div
                className="tab-pane fade accordion-item prr"
                id="profile-tab-pane05"
                role="tabpanel"
                aria-labelledby="profile-tab05"
                tabindex="0"
              >
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse d-lg-block"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#myTabContent"
                >
                   <div className="container">
                      <div className="row gallery align-items-center">
                        <div className="col-sm-12 col-lg-6 mt-5">
                          <img src={require("../assets/logo1.png")} />
                        </div>

                        <div className="col-sm-12 col-lg-6 mt-5">
                          <div className="row" style={{ gap: "20px 0px" }}>
                            <div className="col-lg-6">
                              <img
                                src={require("../assets/logo2.png")}
                              />
                            </div>
                            <div className="col-lg-6">
                              <img
                                src={require("../assets/logo3.png")}
                              />
                            </div>
                            <div className="col-lg-6">
                              <img
                                src={require("../assets/logo4.png")}
                              />
                            </div>
                            <div className="col-lg-6">
                              <img
                                src={require("../assets/logo5.png")}
                              />
                            </div>
                          </div>
                        </div>

                        <div
                          className="custom-price-button"
                          style={{ paddingTop: "70px" }}
                        >
                          <button>Get Started</button>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div>
            <div className="testimonial-title">
              <h2><span>Our</span> Portfolio</h2>
            </div>
            
          </div> */}

          {/* <div className="carousel">
          <Swiper
              className='my-4'
              loop={true}
              spaceBetween={10}
              slidesPerView={1}
            >
              <SwiperSlide ><img onClick={handleShow7} src={require('../assets/test1.png')} alt="Bag 2" /><h3>Branding</h3>
             <Modal  size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       show={show7} onHide={handleClose7}>
                <Modal.Header>
                  <Modal.Title>
                  Branding
                    <div onClick={handleClose7}>
                      <MdCancel />
                    </div>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div class="gallery-container">
					<div class="gallery-item" data-index="1">
						<img src={require('../assets/branding1.png')} />
					</div>
					<div class="gallery-item" data-index="2">
						<img src={require('../assets/branding2.png')} />
					</div>
					<div class="gallery-item" data-index="3">
						<img src={require('../assets/branding3.png')} />
					</div>
					<div class="gallery-item" data-index="4">
						<img src={require('../assets/branding4.png')} />
					</div>
					
				</div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" className="talk-button" onHide={handleClose7}>
                    <img src={require("../assets/mdi_talk.png")} />{" "}
                    <span>Talk To Experts</span>
                  </Button>
                </Modal.Footer>
              </Modal>
              </SwiperSlide>
              <SwiperSlide><img onClick={handleShow8} src={require('../assets/test2.png')} alt="Bag 3" /><h3>Logo Designing</h3></SwiperSlide>
              <Modal  size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       show={show8} onHide={handleClose8}>
                <Modal.Header>
                  <Modal.Title>
                  Logo Designing
                    <div onClick={handleClose8}>
                      <MdCancel />
                    </div>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div class="gallery-container">
					<div class="gallery-item" data-index="1">
						<img src={require('../assets/logo1.png')} />
					</div>
					<div class="gallery-item" data-index="2">
						<img src={require('../assets/logo2.png')} />
					</div>
					<div class="gallery-item" data-index="3">
						<img src={require('../assets/logo3.png')} />
					</div>
					<div class="gallery-item" data-index="4">
						<img src={require('../assets/logo4.png')} />
					</div>
					<div class="gallery-item" data-index="5">
						<img src={require('../assets/logo5.png')} />
					</div>
					<div class="gallery-item" data-index="6">
						<img src={require('../assets/logo6.png')} />
					</div>
					<div class="gallery-item" data-index="7">
						<img src={require('../assets/logo7.png')} />
					</div>
					<div class="gallery-item" data-index="8">
						<img src={require('../assets/logo8.png')} />
					</div>
					<div class="gallery-item" data-index="9">
						<img src={require('../assets/logo9.png')} />
					</div>
					<div class="gallery-item" data-index="10">
						<img src={require('../assets/logo10.png')} />
					</div>
					<div class="gallery-item" data-index="11">
						<img src={require('../assets/logo11.png')} />
					</div>
					<div class="gallery-item" data-index="12">
						<img src={require('../assets/logo12.png')} />
					</div>
				</div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" className="talk-button" onHide={handleClose8}>
                    <img src={require("../assets/mdi_talk.png")} />{" "}
                    <span>Talk To Experts</span>
                  </Button>
                </Modal.Footer>
              </Modal>
              <SwiperSlide><img  onClick={handleShow10} src={require('../assets/test3.png')} alt="Bag 4" /><h3>App Development</h3></SwiperSlide>
              <Modal  size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       show={show10} onHide={handleClose10}>
                <Modal.Header>
                  <Modal.Title>
                  App Development
                    <div onClick={handleClose10}>
                      <MdCancel />
                    </div>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div class="gallery-container">
					<div class="gallery-item" data-index="1">
						<img src={require('../assets/mobile1.png')} />
					</div>
					<div class="gallery-item" data-index="2">
						<img src={require('../assets/mobile2.png')} />
					</div>
					<div class="gallery-item" data-index="3">
						<img src={require('../assets//mobile3.png')} />
					</div>
					<div class="gallery-item" data-index="4">
						<img src={require('../assets//mobile4.png')} />
					</div>
					<div class="gallery-item" data-index="5">
						<img src={require('../assets//mobile5.png')} />
					</div>
					<div class="gallery-item" data-index="6">
						<img src={require('../assets//mobile6.png')} />
					</div>
					
				</div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" className="talk-button" onHide={handleClose10}>
                    <img src={require("../assets/mdi_talk.png")} />{" "}
                    <span>Talk To Experts</span>
                  </Button>
                </Modal.Footer>
              </Modal>
              <SwiperSlide><img onClick={handleShow11} src={require('../assets/test4.png')} alt="Bag 4" /><h3>Website Development</h3></SwiperSlide>
              <Modal  size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       show={show11} onHide={handleClose11}>
                <Modal.Header>
                  <Modal.Title>
                  Website Development
                    <div onClick={handleClose11}>
                      <MdCancel />
                    </div>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div class="gallery-container">
					<div class="gallery-item" data-index="1">
						<img src={require('../assets/web1.png')} />
					</div>
					<div class="gallery-item" data-index="2">
						<img src={require('../assets/web2.png')} />
					</div>
					<div class="gallery-item" data-index="3">
						<img src={require('../assets/web3.png')} />
					</div>
					<div class="gallery-item" data-index="4">
						<img src={require('../assets/web4.png')} />
					</div>
					
				</div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" className="talk-button" onHide={handleClose11}>
                    <img src={require("../assets/mdi_talk.png")} />{" "}
                    <span>Talk To Experts</span>
                  </Button>
                </Modal.Footer>
              </Modal>
              <SwiperSlide><img onClick={handleShow12} src={require('../assets/test5.png')} alt="Bag 4" /><h3>UI UX Designing</h3></SwiperSlide>
              <Modal  size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       show={show12} onHide={handleClose12}>
                <Modal.Header>
                  <Modal.Title>
                  UI UX Designing
                    <div onClick={handleClose12}>
                      <MdCancel />
                    </div>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div class="gallery-container">
					<div class="gallery-item" data-index="1">
						<img src={require('../assets/test1.png')} />
					</div>
					<div class="gallery-item" data-index="2">
						<img src={require('../assets/test1.png')} />
					</div>
					<div class="gallery-item" data-index="3">
						<img src={require('../assets/test1.png')} />
					</div>
					<div class="gallery-item" data-index="4">
						<img src={require('../assets/test1.png')} />
					</div>
					<div class="gallery-item" data-index="5">
						<img src={require('../assets/test1.png')} />
					</div>
					<div class="gallery-item" data-index="6">
						<img src={require('../assets/test1.png')} />
					</div>
					<div class="gallery-item" data-index="7">
						<img src={require('../assets/test1.png')} />
					</div>
					<div class="gallery-item" data-index="8">
						<img src={require('../assets/test1.png')} />
					</div>
				</div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" className="talk-button" onHide={handleClose12}>
                    <img src={require("../assets/mdi_talk.png")} />{" "}
                    <span>Talk To Experts</span>
                  </Button>
                </Modal.Footer>
              </Modal>
             
            </Swiper>
          </div> */}
        </div>
      </section>
    </>
  );
};
