import React, { useEffect, useState } from "react";
import { CountUp } from "use-count-up";


const SecondSec = () => {

  return (
    <>
      <section className="sec-two" id="second">
        <div className="container">
          <div className="row">
            <div className="text-box">
              <h2>Let's grow <span>together!</span></h2>
              <p>
              When you choose to work with Apex Web Experts, you're not just hiring an agency. You're partnering with a dedicated team of professionals invested in your success. We focus on forging long-term relationships with our clients, and we're committed to supporting your business growth every step of the way.
              </p>
            </div>
            <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-6 mt-5 mt-lg-0">
              <div className="count-box">
                <i className="icofont-simple-smile"></i>
                <span data-toggle="counter-up">
                <CountUp isCounting end={372} duration={3.2} />
                </span>
                <p>Happy<br/><span> Clients</span></p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-6 mt-5 mt-lg-0">
              <div className="count-box">
                <i className="icofont-document-folder"></i>
                <span data-toggle="counter-up"><CountUp  isCounting end={13} duration={3.2} /></span>
                <p>Project<br/><span> Completed</span></p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-6 mt-5 mt-lg-0">
              <div className="count-box">
                <i className="icofont-live-support"></i>
                <span data-toggle="counter-up"><CountUp  isCounting end={57} duration={3.2} /></span>
                <p>Years<br/><span> Experience</span></p>
              </div>
            </div>
          
          </div>
        </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SecondSec;
