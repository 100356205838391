import React, { useState } from 'react';
import { FaArrowRight } from 'react-icons/fa6';
import { IoIosArrowForward } from "react-icons/io";

const SidebarContactus = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleForm = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
     
      <div className={`contact-form-container ${isOpen ? 'open' : ''}`}>
      <div className="fixed-buttons1">
        <button onClick={toggleForm}>Contact Us</button>
      </div>
        <div className="contact-form">
          {/* <h2>Contact Us</h2> */}
          <form>
            <input type="text" placeholder="Full Name" />
            <input type="email" placeholder="Email Address" />
            <input type="tel" placeholder="Phone" />
            <textarea placeholder="Project Description"></textarea>
            <button type="submit">Submit</button>
          </form>
        </div>
        <div className="fixed-buttonsArrow">
        <IoIosArrowForward onClick={toggleForm}/>
      </div>
      </div>
      <div className={`fixed-buttons ${isOpen ? 'd-none' : ''}`}>
        <button onClick={toggleForm}>Contact Us</button>
      </div>
    </>
  );
};

export default SidebarContactus;
