import { useEffect, useRef } from "react";
import "./Testimonial.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';
import { Link } from "react-router-dom";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { FaStar } from "react-icons/fa6";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const Testimonial = () => {
  const settings = {
    infinite: true,
    speed: 9000,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: true,
  };
  const swiperRef = useRef(null);
  const testimonial = [
    {
    name: "Isatou Senghore",
    img: require("../assets/client1.png"),
    text: "Working With Apex  Creative  designs was a game changer for my business - their designs & branding strategies have  helped us take our business to the revolusionery",
    stars: 4,
    },
    {
      name: "Isatou Senghore",
      img: require("../assets/client1.png"),
      text: "Working With Apex  Creative  designs was a game changer for my business - their designs & branding strategies have  helped us take our business to the revolusionery",
      stars: 4,
    },
    {
      name: "Isatou Senghore",
      img: require("../assets/client1.png"),
      text: "Working With Apex  Creative  designs was a game changer for my business - their designs & branding strategies have  helped us take our business to the revolusionery",
      stars: 4,
    },
    {
      name: "Isatou Senghore",
      img: require("../assets/client1.png"),
      text: "Working With Apex  Creative  designs was a game changer for my business - their designs & branding strategies have  helped us take our business to the revolusionery",
      stars: 4,
    },
    {
      name: "Isatou Senghore",
      img: require("../assets/client1.png"),
      text: "Working With Apex  Creative  designs was a game changer for my business - their designs & branding strategies have  helped us take our business to the revolusionery",
      stars: 4,
    },
    {
      name: "Isatou Senghore",
      img: require("../assets/client1.png"),
      text: "Working With Apex  Creative  designs was a game changer for my business - their designs & branding strategies have  helped us take our business to the revolusionery",
      stars: 4,
    },
    {
      name: "Isatou Senghore",
      img: require("../assets/client1.png"),
        text: "Working With Apex  Creative  designs was a game changer for my business - their designs & branding strategies have  helped us take our business to the revolusionery",
      stars: 4,
    },
    {
      name: "Isatou Senghore",
      img: require("../assets/client1.png"),
      text: "Working With Apex  Creative  designs was a game changer for my business - their designs & branding strategies have  helped us take our business to the revolusionery",
      stars: 4,
    },
    {
      name: "Isatou Senghore",
      img: require("../assets/client1.png"),
      text: "Working With Apex  Creative  designs was a game changer for my business - their designs & branding strategies have  helped us take our business to the revolusionery",
      stars: 4,
    },
    {
      name: "Isatou Senghore",
      img: require("../assets/client1.png"),
      text: "Working With Apex  Creative  designs was a game changer for my business - their designs & branding strategies have  helped us take our business to the revolusionery",
      stars: 4,
    },
  ];
  return (
    <>
      <section className="teals-testimonials">
        <div className="container">
          <div>
            <div className="testimonial-title">
                <p>Testimonials</p>
              <h2>Satisfied <span>Client’s</span> </h2>
              <p>Solutions to fit every budget and need - our flexible pricing and plans make it easy for you to choose the perfect solution for your brand!</p>
            </div>
            
          </div>
        
          
          <div className="carousel">
          <Slider {...settings}>

          {testimonial.map((item, index) => (
              <div className="testimonials_web" key={index}>
                <p>{item.text}</p>
                <div className="testBox">
                <div className="img-box">
                  <img  src={item.img} alt={item.name}/>
                </div>
                <div>
                <h3>{item.name}</h3>
                <p>
                COO, GreenLeaf Enterprises
                </p>
                </div>
                
                </div>
              </div>
            ))}
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};