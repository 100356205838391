import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../assets/logoApex.png';
import { Link } from 'react-router-dom';

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <>
      <Navbar expand="lg" className={`header-navbar ${scrolled ? 'scrolled' : ''}`}>
        <Container>
          <div className="header-content">
            <div className='mainMenu'>
            <img width={200} src={logo} alt="Apex Logo" />
            <div className='toggle-menu'>
              <ul onClick={toggleDropdown} className={`menu-list ${dropdownOpen ? 'active' : ''}`}>
                <h3>
                  Services
                  <svg
                  style={{ rotate: dropdownOpen ? '45deg' : '', transition: 'all 0.3s linear' }}
                    width="20" height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="Icon / Plus">
                      <path id="Rectangle 3" d="M10 1V3.6C10 5.84021 10 6.96031 9.56403 7.81596C9.18053 8.56861 8.56861 9.18053 7.81596 9.56403C6.96031 10 5.84021 10 3.6 10H1" stroke="currentColor" vector-effect="non-scaling-stroke" strokeLinecap="round" />
                      <path id="Rectangle 4" d="M19 10H16.4C14.1598 10 13.0397 10 12.184 10.436C11.4314 10.8195 10.8195 11.4314 10.436 12.184C10 13.0397 10 14.1598 10 16.4V19" stroke="currentColor" vector-effect="non-scaling-stroke" strokeLinecap="round" />
                    </g>
                  </svg>
                </h3>
                <div className={`dropdown-content ${dropdownOpen ? 'active' : ''}`}>
                  <Link to="/" className="dropdown-link">Home</Link>
                  <Link to="/about" className="dropdown-link">About</Link>
                  <Link to="/contact-us" className="dropdown-link">Contact Us</Link>
                  <Link to="/all-service" className="dropdown-link">Services</Link>
                </div>
              </ul>
            </div>
            </div>
            <button className="btn btn-outline-light">Get Started</button>
          </div>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
