import { useState } from "react";
import { FaHeart, FaMinimize, FaRegHeart } from "react-icons/fa6";
import { FaShoppingCart } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { MdCancel } from "react-icons/md";

const FifthSec = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);

  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [show5, setShow5] = useState(false);

  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [show6, setShow6] = useState(false);

  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  return (
    <>
      <section className="sec-fifth">
        <div className="container">
          <div className="row">
            <div className="text-box">
              <h2>
                Services we can
                <br />
                <span> help </span>you with
              </h2>
            </div>
            <div className="col-lg-12 d-flex gap-4 mt-4">
              <div className="hoverable">
                <div className="innerbox1" onClick={handleShow}>
                  <h3>Logo Designing</h3>
                  <p>
                    When you choose to work with Apex Web Experts, you're not
                    just hiring an agency. You're partnering
                  </p>
                </div>
              </div>
              <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                  <Modal.Title>
                    LOGO DESIGNING{" "}
                    <div onClick={handleClose}>
                      <MdCancel />
                    </div>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Strengthen your brand with logo design australia. Developing a
                  distinctive logo for your business is an important goal, and
                  we at Apex Web Experts know that. Our specialty is creating
                  logos that not only look good but also have significance for
                  your target audience.
                  <br />
                  <br />
                  Working together, we can determine the fundamental principles
                  of your brand, which will allow us to create an unforgettable
                  design. We can design a logo that meets your needs whether
                  you're a startup or an established company. From the very
                  beginning to the very end, we keep your brand's identity and
                  values front and centre.
                  <br />
                  <br />
                  <b>So, what makes us the best choice to design your logo?</b>
                  <ul>
                    <li>
                      professional logo designs that are original and made just
                      for your company.
                    </li>
                    <li>
                      Expert designers dedicated to creating first-rate logo
                      design for company
                    </li>
                    <li>
                      The process is iterated until the customer is entirely
                      satisfied. Affordable quality that won't break the bank.
                    </li>
                  </ul>
                  Is your company's name prepared to shine? Get in touch with us
                  immediately if you would like a logo that will stay in
                  people's minds.
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" className="talk-button">
                    <img src={require("../assets/mdi_talk.png")} />{" "}
                    <span>Talk To Experts</span>
                  </Button>
                </Modal.Footer>
              </Modal>
              <div className="hoverable">
                <div className="innerbox2" onClick={handleShow2}>
                  <h3>SEO</h3>
                  <p>
                    When you choose to work with Apex Web Experts, you're not
                    just hiring an agency. You're partnering
                  </p>
                </div>
              </div>
              <Modal show={show2} onHide={handleClose2}>
                <Modal.Header>
                  <Modal.Title>
                  SEO
                    <div onClick={handleClose2}>
                      <MdCancel />
                    </div>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                Use our seo services australia to increase your online presence
If you want your website to rank higher and get more natural visitors, Apex Web Experts is the right place to go for seo optimization services. To increase your online visibility and achieve quantifiable results, we develop data-driven SEO campaigns.
                  <br />
                  <br />
                  We will first carefully examine your website and those of your competitors to identify areas that can be improved. Next, we optimize your website for search engines using on-page and off-page SEO strategies, which include content creation, keyword optimization, and link generation. We guarantee that your website will rank higher, engage visitors and convert them, with a focus on long-term sustainable development.
                  <br />
                  <br />
                  <b>How do our SEO services differ?</b>
                  <ul>
                    <li>
                    Strategies specifically tailored to your company's needs and desired market.
                    </li>
                    <li>
                    Conduct an in-depth SEO audit to identify problem areas and find solutions.
                    </li>
                    <li>
                    Technical SEO, content creation and keyword research are part of expert optimization.
                    </li>
                    <li>
                    Accessible reporting to monitor development and evaluate results.
                    </li>
                  </ul>
                  Use our ecommerce seo services to make your website shine. If you want to increase the visibility of your website in search engines and attract more visitors, contact us now.
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" className="talk-button">
                    <img src={require("../assets/mdi_talk.png")} />{" "}
                    <span>Talk To Experts</span>
                  </Button>
                </Modal.Footer>
              </Modal>
              <div className="hoverable">
                <div className="innerbox3" onClick={handleShow3}>
                  <h3>BRANDING</h3>
                  <p>
                    When you choose to work with Apex Web Experts, you're not
                    just hiring an agency. You're partnering
                  </p>
                </div>
              </div>
              <Modal show={show3} onHide={handleClose3}>
                <Modal.Header>
                  <Modal.Title>
                  Branding
                    <div onClick={handleClose3}>
                      <MdCancel />
                    </div>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                Get branding services to create a good reputation for your business.<br/>At Apex Web Experts, we know that strong branding is vital to the success of any business. To help your business stand out from the competition, we offer Digital branding services to establish a consistent and engaging identity.
                  <br />
                  <br />
                  We work with you to develop a brand strategy that reflects your goals and principles. We ensure that everything from your logo to your brand messaging aligns with your company's goals and appeals to your target audience. The goal of our strategy is to build brand awareness and loyalty by delivering a consistent and memorable experience.
                    <br />
                  <br />
                  <b>Our branding services australia are second to none.</b>
                  <ul>
                    <li>
                    Individual branding strategies that reflect your company's values ​​and competitive landscape.
                    </li>
                    <li>
                    Logos, colour palettes and typography are all part of the custom design package.
                    </li>
                    <li>
                    Strict branding standards to ensure cohesion across all media.
                    </li>
                    <li>
                    Professionals dedicated to creating memorable customer experiences for brands.
                    </li>
                  </ul>
                  Build your company's success by creating a brand that embodies your values. Contact us now to take the first step towards creating a strong and memorable brand.
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" className="talk-button">
                    <img src={require("../assets/mdi_talk.png")} />{" "}
                    <span>Talk To Experts</span>
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
            <div className="col-lg-12 d-flex gap-4 mt-4">
              <div className="hoverable">
                <div className="innerbox4" onClick={handleShow4}>
                  <h3>APP DEVELOPMENT</h3>
                  <p>
                    When you choose to work with Apex Web Experts, you're not
                    just hiring an agency. You're partnering
                  </p>
                </div>
              </div>
              <Modal show={show4} onHide={handleClose4}>
                <Modal.Header>
                  <Modal.Title>
                  Mobile Application
                    <div onClick={handleClose4}>
                      <MdCancel />
                    </div>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                Innovative mobile apps that enhance user experience and drive business development are our strength at Apex Web Experts. To ensure your users have fun and achieve their goals, we offer custom mobile application development services.
                  <br />
                  <br />
                  From the initial concept and design to the final deployment, we take care of everything in app development. To build secure, scalable, and user-friendly applications, our team follows industry standards and leverages cutting-edge technology. Whether you need a native iOS or Android app or a cross-platform solution, we offer best-in-class solutions that align with your company's vision.
                    <br />
                  <br />
                  <b>Why should you use our mobile app designers Australia?</b>
                  <ul>
                    <li>
                    Solutions tailored specifically to the needs of your business and its users.
                    </li>
                    <li>
                    The intuitive design guarantees a pleasant and hassle-free experience.
                    </li>
                    <li>
                    Qualified development in accordance with current best practices and industry requirements.
                    </li>
                    <li>
                    Support from all staff during and after development.
                    </li>
                  </ul>
                  A strong, distinctive mobile app can take your business to new heights. Contact us now if you would like to start developing an attractive and successful mobile app.
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" className="talk-button">
                    <img src={require("../assets/mdi_talk.png")} />{" "}
                    <span>Talk To Experts</span>
                  </Button>
                </Modal.Footer>
              </Modal>
              <div className="hoverable">
                <div className="innerbox5" onClick={handleShow5}>
                  <h3>WEB DEVELOPMENT</h3>
                  <p>
                    When you choose to work with Apex Web Experts, you're not
                    just hiring an agency. You're partnering
                  </p>
                </div>
              </div>
              <Modal show={show5} onHide={handleClose5}>
                <Modal.Header>
                  <Modal.Title>
                  Website Designs
                    <div onClick={handleClose5}>
                      <MdCancel />
                    </div>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                Your online visibility can be drastically improved with a well-designed website. At Apex Web Experts, our specialty is creating visually appealing and functionally sound websites through our website development services. In response to specific consumer needs and preferences, the design team can create completely original website layouts as part of our website development company in Australia.
                  <br />
                  <br />
                  Thanks to its elegant design and intuitive layout, this theme will give your website a professional look and keep it running like clockwork. The main goal is to make it as easy as possible to find, engage, and convert every visitor. Our web planning activities are monitored by you, ensuring custom designs that grab your target audience's attention and showcase the soul of your gear.
                    <br />
                  <br />
                  The user-centric design makes for a fun and easy experience. To make it work seamlessly on your computer or mobile device, you can easily adjust the layout. We offer fast and easy delivery, including all necessary repairs, to ensure your complete satisfaction. A higher online profile may be within your reach. If you're ready to start a website that makes an impact and generates revenue, contact us.
                 
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" className="talk-button">
                    <img src={require("../assets/mdi_talk.png")} />{" "}
                    <span>Talk To Experts</span>
                  </Button>
                </Modal.Footer>
              </Modal>
              <div className="hoverable">
                <div className="innerbox6" onClick={handleShow6}>
                  <h3>Social media</h3>
                  <p>
                    When you choose to work with Apex Web Experts, you're not
                    just hiring an agency. You're partnering
                  </p>
                </div>
              </div>
              <Modal show={show6} onHide={handleClose6}>
                <Modal.Header>
                  <Modal.Title>
                  Social Media Marketing
                    <div onClick={handleClose6}>
                      <MdCancel />
                    </div>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                Professional social media marketing can increase your brand awareness<br/>If you want your brand to stand out and connect with the people who matter most to you, Apex Web Experts is the agency for you. To generate leads and strengthen relationships with your customers, we offer social media marketing services customized to your needs.
                  <br />
                  <br />
                  We develop and implement data-driven plans that meet your business objectives. We ensure your social media presence is optimized for success in every way, from creating engaging content to managing advertising campaigns to tracking performance indicators. The foundation of our strategy is to generate real engagement and achieve quantifiable results.
                    <br />
                  <br />
                 <b>What sets our social media advertising services apart?</b>
                 <ul>
                    <li>
                    Tailored approaches aligned with your brand's values ​​and objectives.
                    </li>
                    <li>
                    Create material that stays in people's minds and fascinates them.
                    </li>
                    <li>
                    Marketing that targets a specific audience to attract and retain consumers.
                    </li>
                    <li>
                    Reports and analytics to monitor progress and refine approaches.
                    </li>
                  </ul>
                  To expand your brand's reach and achieve your marketing goals, we can help you make the most of social media. Contact us now if you want to start a successful social media campaign.
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" className="talk-button">
                    <img src={require("../assets/mdi_talk.png")} />{" "}
                    <span>Talk To Experts</span>
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FifthSec;
