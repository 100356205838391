import React, { useEffect, useState } from 'react';
import Main from '../ServicesComponent/AllServices/Main'

const Services = () => {
    const [offsetY, setOffsetY] = useState(0);

  const handleScroll = () => {
    setOffsetY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
    <div
        className="services-container"
        style={{
          backgroundPositionY: `${offsetY * 0.5}px`, // Adjust this multiplier to control the speed
        }}
      >
      <Main/>
      </div>
    </>
  )
}

export default Services
