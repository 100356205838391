import React, { useState } from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './component/screens/Home';
import Header from './component/HeaderFooterr/Header';
import Footer from './component/HeaderFooterr/Footer';
import SidebarContactus from './component/HomeComponent/SidebarContactus';
import { useSpring, animated } from '@react-spring/web';
import { Cursor } from './component/screens/Cursor';
import Services from './component/screens/Services';

function App() {
  const [isHovering, setIsHovering] = useState(false);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  const [animatedProps, setAnimatedProps] = useSpring(() => ({
    transform: `translate3d(0px, 0px, 0)`
  }));

  const handleMouseMove = (event) => {
    setCursorPosition({ x: event.clientX, y: event.clientY });
    setAnimatedProps({
      transform: `translate3d(${event.clientX}px, ${event.clientY}px, 0)`
    });
  };

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <div onMouseMove={handleMouseMove} className="App">
      <Header />
      <SidebarContactus />
      <Routes>
        <Route path="/" element={<Home onHoverEnter={handleMouseEnter} onHoverLeave={handleMouseLeave} />} />
        <Route path="/all-service" element={<Services onHoverEnter={handleMouseEnter} onHoverLeave={handleMouseLeave} />} />
      </Routes>
      <Footer />
      <div className="containerCursor">
        <animated.div style={animatedProps} className="wrapperCursor">
          <Cursor />
        </animated.div>
      </div>

      {isHovering && (
        <div
          className="cursor-hover show"
          style={{ top: cursorPosition.y, left: cursorPosition.x }}
        >
          Open
        </div>
      )}
    </div>
  );
}

export default App;
