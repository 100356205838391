import React from 'react';
import { FaArrowRight } from "react-icons/fa6";
import { LuToggleLeft } from "react-icons/lu";
import banner from '../assets/banner.mp4';

const Main = () => {
  return (
    <>
       
       <div className="video-container">
        <video className="videoTag" autoPlay loop muted>
          <source src={banner} type='video/mp4' />
        </video>
        <section className="overlay bg-sec">
          <div className="container fjffjf">
            <div className="row">
              <div className="col-lg-8">
               <div className='texter'>
               <h2 className="heading">
                Creativity In Our
                </h2>
                <h4>
                Veins
                </h4>
               </div>
               <div className='second-para'>
                  <p>
                  Fuel your <span>digital success</span> with Apex Web Experts. Our of marketing mavens is ready to take your business to the next level!
                  </p>
                  <button>
                  Discover More
                  </button>
                </div>
              </div>
              
              <div className="col-lg-4">
              <div className="contact-form">
          {/* <h2>Contact Us</h2> */}
          <form>
            <input type="text" placeholder="Full Name" />
            <input type="email" placeholder="Email Address" />
            <input type="tel" placeholder="Phone" />
            <textarea placeholder="Project Description"></textarea>
            <button type="submit">Submit</button>
          </form>
        </div>
              </div>
              <div className="col-lg-12">
                <div className='text-center toggle'>
                  <span>
                  <a href='#second'><LuToggleLeft /></a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
        </div>
    </>
  );
};

export default Main;
