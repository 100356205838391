import React from 'react'
import Main from '../HomeComponent/Main'
import SecondSec from '../HomeComponent/SecondSec'
import FifthSec from '../HomeComponent/FifthSec'
import { Testimonial } from '../HomeComponent/Testiomonial'
import Packages from '../HomeComponent/Packages'
import { OurPortfolio } from '../HomeComponent/OurPortfolio'
import Faqs from '../HomeComponent/Faqs'
import ThirdSec from '../HomeComponent/ThirdSec'

const Home = () => {
  return (
    <>
      <Main/>
      <SecondSec/>
      <ThirdSec/>
      <FifthSec/>
      <OurPortfolio/>
      <Packages/>
      <Faqs/>
      <Testimonial/>
    </>
  )
}

export default Home
