import React, { useState, useEffect } from 'react';
import { PiPlus } from 'react-icons/pi';
import './faqs.css';

const Faqs = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });

  const toggleFaq = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // Function to update cursor position on mouse move
  const handleMouseMove = (event) => {
    setCursorPos({
      x: event.clientX,
      y: event.clientY,
    });
  };

  const faqs = [
    {
      question: "What is Apex Web Expert?",
      answer: "Apex Web Expert is a web development company that provides high-quality web development services to businesses and individuals. We offer a range of web development services, including website design, development, and maintenance.",
    },
    {
      question: "What is Apex Web Expert's mission?",
      answer: "Our mission is to provide high-quality web development services to businesses and individuals. We strive to provide the best web development solutions for our clients, while ensuring that our work is of the highest quality.",
    },
    {
      question: "What is Apex Web Expert's approach to web development?",
      answer: "Our approach to web development is to provide a comprehensive range of services, including website design, development, and maintenance. We use cutting-edge technologies and best practices to ensure that our work is of the highest quality.",
    },
    {
      question: "What is Apex Web Expert's approach to client satisfaction?",
      answer: "Our approach to client satisfaction is to provide a high level of customer service. We provide personalized attention to each client, ensuring that they receive the best possible service.",
    },
    {
      question: "What is Apex Web Expert's approach to pricing?",
      answer: "Our pricing model is based on the amount of work required and the complexity of the project. We offer a range of pricing options, including hourly, monthly, and yearly rates.",
    },
    {
      question: "What is Apex Web Expert's approach to payment?",
      answer: "Our payment model is based on a fixed-price monthly subscription model. We offer a range of payment options, including credit card, PayPal, and wire transfer.",
    },
    {
      question: "What is Apex Web Expert's approach to support?",
      answer: "Our support model is based on a 24/7 customer support team. We provide a range of support options, including email, phone, and live chat support.",
    },
  ];

  
  return (
    <section className="faq-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
            </div>
           
          </div>
          <div className='col-lg-2'></div>
          <div className="col-lg-8">
          <div className="faq-wrapper">
            {faqs.map((faq, index) => (
                <div key={index} className="faq-item">
                  <div
                    className="d-flex justify-content-between align-items-center question"
                    onClick={() => toggleFaq(index)}
                    onMouseMove={handleMouseMove}
                  >
                    <h4>{faq.question}</h4>
                    <div className={`icon ${openIndex === index ? 'rotate' : ''}`}>
                      <PiPlus />
                    </div>
                    {/* Custom "Open" text following the cursor */}
                    <div
                      className="open-text-cursor"
                      style={{
                        top: `${cursorPos.y}px`,
                        left: `${cursorPos.x}px`,
                      }}
                    >
                      Open
                    </div>
                  </div>
                  <div className={`answer ${openIndex === index ? 'show' : ''}`}>
                    <p>{faq.answer}</p>
                  </div>
                </div>
              ))}
            </div>           
          </div>
          <div className='col-lg-2'></div>
        </div>
      </div>
    </section>
  );
};

export default Faqs;
