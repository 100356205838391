import React from 'react'
import { BiStar } from 'react-icons/bi';
import { BsWordpress } from 'react-icons/bs';
import { CgWebsite } from 'react-icons/cg';
import { CiMobile3 } from 'react-icons/ci';
import { FaSchoolCircleExclamation } from 'react-icons/fa6';
import { IoLogoAndroid, IoLogoWordpress } from 'react-icons/io';
import { MdOutlineContentPaste } from 'react-icons/md';
import { SiAdobeindesign } from 'react-icons/si';
import { TbSeo, TbSocial } from 'react-icons/tb';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const ThirdSec = () => {
  return (
    <>
      <VerticalTimeline>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'linear-gradient(177deg, #3b008c, #ec008c)', color: '#fff', textAlign:'left' }}
    contentArrowStyle={{ borderRight: '7px solid  #fff' }}
    // date="2011 - present"
    iconStyle={{ background: '#fff', color: '#BE1BFF' }}
    icon={<CgWebsite />}
  >
    <h3 className="vertical-timeline-element-title">Creative Director</h3>
    <h4 className="vertical-timeline-element-subtitle">Miami, FL</h4>
    <p>
      Creative Direction, User Experience, Visual Design, Project Management, Team Leading
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'linear-gradient(177deg, #3b008c, #ec008c)', color: '#fff', textAlign:'right' }}
    contentArrowStyle={{ borderRight: '7px solid  #fff' }}
    // date="2010 - 2011"
    iconStyle={{ background: '#fff', color: '#BE1BFF' }}
    icon={<CiMobile3 />}
  >
    <h3 className="vertical-timeline-element-title">Art Director</h3>
    <h4 className="vertical-timeline-element-subtitle">San Francisco, CA</h4>
    <p>
      Creative Direction, User Experience, Visual Design, SEO, Online Marketing
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'linear-gradient(177deg, #3b008c, #ec008c)', color: '#fff', textAlign:'left' }}
    // date="2008 - 2010"
    iconStyle={{ background: '#fff', color: '#BE1BFF' }}
    icon={<TbSeo />}
  >
    <h3 className="vertical-timeline-element-title">Web Designer</h3>
    <h4 className="vertical-timeline-element-subtitle">Los Angeles, CA</h4>
    <p>
      User Experience, Visual Design
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'linear-gradient(177deg, #3b008c, #ec008c)', color: '#fff', textAlign:'right' }}
    // date="2006 - 2008"
    iconStyle={{ background: '#fff', color: '#BE1BFF' }}
    icon={<TbSocial />}
  >
    <h3 className="vertical-timeline-element-title">Web Designer</h3>
    <h4 className="vertical-timeline-element-subtitle">San Francisco, CA</h4>
    <p>
      User Experience, Visual Design
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    contentStyle={{ background: 'linear-gradient(177deg, #3b008c, #ec008c)', color: '#fff', textAlign:'left' }}
    // date="April 2013"
    iconStyle={{ background: '#fff', color: '#BE1BFF' }}
    icon={<SiAdobeindesign />}
  >
    <h3 className="vertical-timeline-element-title">Content Marketing for Web, Mobile and Social Media</h3>
    <h4 className="vertical-timeline-element-subtitle">Online Course</h4>
    <p>
      Strategy, Social Media
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    contentStyle={{ background: 'linear-gradient(177deg, #3b008c, #ec008c)', color: '#fff', textAlign:'right' }}
    // date="November 2012"
    iconStyle={{ background: '#fff', color: '#BE1BFF' }}
    icon={<IoLogoWordpress />}
  >
    <h3 className="vertical-timeline-element-title">Agile Development Scrum Master</h3>
    <h4 className="vertical-timeline-element-subtitle">Certification</h4>
    <p>
      Creative Direction, User Experience, Visual Design
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    contentStyle={{ background: 'linear-gradient(177deg, #3b008c, #ec008c)', color: '#fff', textAlign:'left' }}
    // date="2002 - 2006"
    iconStyle={{ background: '#fff', color: '#BE1BFF' }}
    icon={<IoLogoAndroid />}
  >
    <h3 className="vertical-timeline-element-title">Bachelor of Science in Interactive Digital Media Visual Imaging</h3>
    <h4 className="vertical-timeline-element-subtitle">Bachelor Degree</h4>
    <p>
      Creative Direction, Visual Design
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    iconStyle={{ background: '#BE1BFF', color: '#fff' }}
    icon={<BiStar />}
  />
</VerticalTimeline>
    </>
  )
}

export default ThirdSec
