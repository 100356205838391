import React, { useEffect, useState } from 'react';
import { BsInstagram } from 'react-icons/bs';
import { CgWebsite } from 'react-icons/cg';
import { DiAndroid } from 'react-icons/di';
import { HiMiniArrowSmallRight } from 'react-icons/hi2';
import { MdBrandingWatermark, MdWeb } from 'react-icons/md';
import { SiAdobeindesign } from 'react-icons/si';
import { TbSeo, TbSocial } from 'react-icons/tb';

const servicesData = [
  {
    id: 1,
    icon: <SiAdobeindesign />,
    title: "Logo Designing",
    description:
      "Strengthen your brand with logo design australia. Developing a distinctive logo for your business is an important goal, and we at Apex Web Experts know that. Our specialty is creating logos that not only look good but also have significance for your target audience.",
  },
  {
    id: 2,
    icon: <TbSeo />,
    title: "SEO",
    description:
      "Use our seo services australia to increase your online presence. If you want your website to rank higher and get more natural visitors, Apex Web Experts is the right place to go for seo optimization services.",
  },
  {
    id: 3,
    icon: <MdBrandingWatermark />,
    title: "Branding",
    description:
      "Get branding services to create a good reputation for your business. To help your business stand out from the competition, we offer digital branding services to establish a consistent and engaging identity.",
  },
  {
    id: 4,
    icon: <DiAndroid />,
    title: "Mobile Application",
    description:
      "Innovative mobile apps that enhance user experience and drive business development are our strength at Apex Web Experts.",
  },
  {
    id: 5,
    icon: <MdWeb />,
    title: "Website Designs",
    description:
      "Your online visibility can be drastically improved with a well-designed website. Our specialty is creating visually appealing and functionally sound websites through our website development services.",
  },
  {
    id: 6,
    icon: <TbSocial />,
    title: "Social Media Marketing",
    description:
      "Professional social media marketing can increase your brand awareness. To generate leads and strengthen relationships with your customers, we offer customized social media marketing services.",
  },
];

const Main = () => {
  const [offsetY, setOffsetY] = useState(0);

  const handleScroll = () => {
    setOffsetY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const truncateDescription = (description, limit) => {
    return description.length > limit
      ? description.slice(0, limit) + "..."
      : description;
  };

  return (
    <section className="hero">
      <div className="mainHeading">
        <h2>
          Discover <br /> Our Services
        </h2>
      </div>
      <div className="container">
        <div className="row w-full">
          {servicesData.map((service) => (
            <div className="col-lg-6" key={service.id}>
              <div className="services-box">
                <div className="icon">{service.icon}</div>
                <div>
                  <h2>{service.title}</h2>
                  <p>{truncateDescription(service.description, 100)}</p> {/* Limit to 100 characters */}
                </div>
                <button className="btn">
                  Learn More <span>
                    <HiMiniArrowSmallRight />
                  </span>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Main;
